import React from 'react';
import Layout from '../../components/Layout/layout';
import { Box, makeStyles, Typography, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 115px)',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: theme.spacing(0),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  iframeContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
}));

const DashboardQuicksight = ({ title, src }) => {
  const classes = useStyles();
  return (
    <Layout title={title}>
      <Box className={classes.root}>
        <Paper elevation={3} className={classes.header}>
          <Typography variant="h5" component="h1">
            {title}
          </Typography>
        </Paper>
        <Box className={classes.iframeContainer}>
          <iframe
            src={src}
            title={title}
            className={classes.iframe}
          ></iframe>
        </Box>
      </Box>
    </Layout>
  );
};

export default DashboardQuicksight;
