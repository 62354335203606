import React, { Fragment, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ModalConfirm from "../../components/Modals/ModalConfirm";
import Layout from "../../components/Layout/layout";
import { DataGrid } from 'devextreme-react';
import { Column, FilterRow } from 'devextreme-react/data-grid';
import {
  Typography,
  IconButton,
  Box,
  Dialog,
  LinearProgress,
  Fab
} from '@material-ui/core'
import { Add, Check, Close } from '@material-ui/icons'
import { red, green } from '@material-ui/core/colors'
import http from '../../Utils/http';
import UsuarioEdit from '../../components/Admin/UsuarioEdit';
import { getIdentity } from '../../Utils/auth';

const MantenimientoUsuarios = () => {
  const [dialog, setDialog] = useState(false);
  const [idUsuario, setIdUsuario] = useState(null);
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [dgCrear, setDgCrear] = useState(false)
  const [dgEditar, setDgEditar] = useState(false)
  const [dgModificar, setDgModificar] = useState(false)
  const [selected, setSelected] = useState(null)

  const getUsuarios = async () => {
    try {
      setLoading(true)
      const data = await http.get('/maestros/usuario')
      const usuarios = data.map(i => {
        const rol = roles.find(j => i.IdRol == j.IdRol)
        return {
          ...i,
          Rol: rol.Nombre
        }
      })
      setItems(usuarios)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  const insertUsuario = async (data) => {
    await http.post('/maestros/usuario', {
      ...data,
      UsuarioCreo: getIdentity()
    })
  }
  const updateUsuario = async (data) => {
    await http.put(`/maestros/usuario/${selected.IdUsuario}`, {
      ...data,
      UsuarioModifico: getIdentity()
    })
  }
  const getRoles = async () => {
    try {
      setLoading(true)
      const data = await http.get('/maestros/rol')
      setRoles(data)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
  }
  const showEdit = (data) => {
    console.log(data)
    setSelected({...data})
    setDgEditar(true)
  }
  const cerrarDialogo = () => {
    setDialog(false);
    setIdUsuario(null)
  }
  const refresh = () => {
    getUsuarios()
    setDgCrear(false)
    setDgEditar(false)
  }
  const eliminarUsuario = async (IdUsuario) => {
    if (window.confirm('¿Estas seguro de borrar el registro?')) {
      try {
        await http.delete(`/maestros/usuario/${IdUsuario}`, {data: {UsuarioModifico: getIdentity()}})
        getUsuarios()
        toast.success('Registro eliminado')
      } catch (error) {
        toast.error('Ha ocurrido un error')
      }
    }
  }
  const renderAlertaConfirm = () => (
    <ModalConfirm
      open={dialog}
      cerrarDialogo={cerrarDialogo}
      titulo="¿Desea eliminar a este usuario?"
      descripcion="Se eliminará al usuario y ya no podrá tener acceso."
      handleAction={eliminarUsuario}
    />
  )
  const CellOpciones = (cellData) => {
    return <>
      <IconButton
        onClick={() => showEdit(cellData.data)}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        onClick={() => eliminarUsuario(cellData.data.IdUsuario)}
      >
        <DeleteIcon />
      </IconButton>
    </>
  }
  const CellTienePassword = (cellData) => {
    return cellData.data.TienePassword
      ? <Check style={{color: green[500]}} />
      : <Close style={{color: red[500]}} />
  }

  useEffect(() => {
    getRoles()
  }, [])
  useEffect(() => {
    if (roles.length) {
      getUsuarios()
    }
  }, [roles])

  return (
    <Layout title="Control de usuarios">
      <Fragment>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" component="p">Usuarios</Typography>
          <Fab
            size="small"
            style={{background: green[500], color: '#FFF'}}
            onClick={() => setDgCrear(true)}
          >
            <Add />
          </Fab>
        </Box>
        {renderAlertaConfirm()}
        {
          loading && <LinearProgress />
        }
        <DataGrid
          dataSource={items}
          showBorders
          showColumnLines
          rowAlternationEnabled
          height="80vh"
        >
          <FilterRow visible={ true} />
          <Column caption="Id" dataField="IdUsuario" />
          <Column caption="DPI" dataField="DPI" />
          <Column caption="Nombre" dataField="Nombre" />
          <Column caption="Usuario AD" dataField="UsuarioAD" />
          <Column caption="Rol" dataField="Rol" />
          <Column caption="Tiene contraseña" cellRender={CellTienePassword} />
          <Column caption="Opciones" cellRender={CellOpciones} />
        </DataGrid>
      </Fragment>
      <Dialog open={dgEditar} fullWidth maxWidth="md">
        <UsuarioEdit
          onClose={() => setDgEditar(false)}
          onRefresh={refresh}
          onSubmit={updateUsuario}
          values={selected}
        />
      </Dialog>
      <Dialog open={dgCrear} fullWidth maxWidth="md">
        <UsuarioEdit
          onClose={() => setDgCrear(false)}
          onRefresh={refresh}
          onSubmit={insertUsuario}
        />
      </Dialog>
    </Layout>
  )
}

export default MantenimientoUsuarios;