import React, { useState, useEffect } from 'react';
import { Button, TextField, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useStoreState } from 'easy-peasy';
import LoadingButton from '../Utils/LoadingButton';
import { toast } from 'react-toastify';
import http from '../../Utils/http';
import { getIdentity } from '../../Utils/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const EnviarMensaje = ({ open, handleClose, dataGIO }) => {
  const classes = useStyles();
  const [mensaje, setMensaje] = useState('');
  const [loading, setLoading] = useState(false);
  const [tipoMensaje, setTipoMensaje] = useState('NORMAL');
  const [error, setError] = useState(null);
  const { maquinaria } = useStoreState(state => ({
    maquinaria: state.maquinaria
  }));

  useEffect(() => {
    setMensaje('');
  }, [open]);

  const validate = () => {
    if (!mensaje.trim()) {
      setError('El mensaje no puede estar vacío.');
      return false;
    }

    if (mensaje.length > 2048) {
      setError('El mensaje no puede tener más de 2048 caracteres.');
      return false;
    }

    return true
  }

  const handleEnviar = async () => {
    if (!validate()) return;
    try {
      setLoading(true);
      const IdMaquinaria = maquinaria.find(m => m.Codigo === dataGIO.codigo_maquinaria).IdMaquinaria;
      const body = {
        IdMaquinaria,
        Mensaje: mensaje,
        UsuarioCreo: getIdentity(),
        TipoMensaje: tipoMensaje
      }
      await http.post('/maestros/mensaje', body)
      handleClose();
    } catch (error) {
      toast.error('Ocurrió un error al enviar el mensaje.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <DialogTitle>Enviar mensaje</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <TextField
            fullWidth
            label="Mensaje"
            multiline
            rows={4}
            variant="outlined"
            value={mensaje}
            onChange={(e) => setMensaje(e.target.value)}
            error={error}
            helperText={error}
          />
          <TextField
            select
            label="Tipo de Mensaje"
            value={tipoMensaje}
            onChange={(e) => setTipoMensaje(e.target.value)}
            variant="outlined"
            fullWidth
            SelectProps={{
              native: true,
            }}
          >
            <option value="NORMAL">Normal</option>
            <option value="ADVERTENCIA">Advertencia</option>
            <option value="CRITICO">Crítico</option>
          </TextField>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton
          onClick={handleEnviar}
          color="primary"
          variant="contained"
          startIcon={<Send />}
          loading={loading}
        >
          Enviar
        </LoadingButton>
      </DialogActions>
    </div>
  );
}

export default EnviarMensaje;
